<template>
  <div class="row">
    <div class="col-12">
      <Breadcrumb :items="breadcrumbs" />
      <div class="lk">
        <div class="row">
          <div class="col-lg-3">
            <div class="lk-nav">
              <UserBar />
              <Sidebar />
            </div>
          </div>
          <div class="col-lg-9">
            <div class="lk__blocks">
              <UserInfo />
              <ContactInfo />
              <Outlets />
              <Schedule />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Callback />
</template>
<script>
import { useMeta } from 'vue-meta'
import { useI18n } from 'vue-i18n'
import Callback from './partials/callback'
import UserBar from './partials/userBar'
import Sidebar from './partials/sidebar'
import Outlets from './partials/outlets'
import ContactInfo from './partials/contactInfo'
import UserInfo from './partials/userInfo'
import Schedule from './partials/schedule'
export default {
  components: {
    Schedule,
    Callback,
    UserBar,
    Sidebar,
    Outlets,
    ContactInfo,
    UserInfo
  },
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('account')
    })
    return {
      t,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('account'), href: null, active: true }
      ]
    }
  }
}
</script>
