<template>
  <div class="lk__block">
    <div class="lk__block-title">01. Личные данные</div>

    <div class="lk__block-inner">
      <div class="row lk__block-info">
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Имя</div>
            <div class="field__value"><b>Игорь</b></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Фамилия</div>
            <div class="field__value"><b>Харченко</b></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Отчество</div>
            <div class="field__value">Не указано</div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Название организации</div>
            <div class="field__value"><b>Пример</b></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">ЕГРПОУ / ИНН</div>
            <div class="field__value">Не указано</div>
          </div>
        </div>
        <div class="col-md-12 text-end mb-3">
          <a href="" class="link link--small text-end">Редактировать данные</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {}
</script>
