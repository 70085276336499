<template>
  <div class="lk__block">
    <div class="lk__block-title">02. Контактная информация</div>

    <div class="lk__block-inner">
      <div class="row lk__block-info">
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Код клиента</div>
            <div class="field__value"><span>BH53it4G9d</span></div>
          </div>
        </div>
        <div class="col-md-8 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Электронная почта</div>
            <div class="field__value"><b>igorharchenko@gmail.com</b></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Имя</div>
            <div class="field__value"><b>Игорь</b></div>
          </div>
        </div>
        <div class="col-md-8 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Подтвержденный телефон</div>
            <div class="field__value"><b>+38 (096) 242 1301</b></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Имя</div>
            <div class="field__value"><b>Михаил</b></div>
          </div>
        </div>
        <div class="col-md-8 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Подтвержденный телефон</div>
            <div class="field__value"><b>+38 (096) 242 1301</b></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Имя</div>
            <div class="field__value"><b>Антон</b></div>
          </div>
        </div>
        <div class="col-md-8 col-sm-6 lk__block-info-item">
          <div class="field">
            <div class="field__title">Подтвержденный телефон</div>
            <div class="field__value"><b>+38 (096) 242 1301</b></div>
          </div>
        </div>
        <div class="col-md-12 text-end mb-3">
          <a href="" class="link link--small">Редактировать данные</a>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
}
</script>
